<template>
  <div class="wrap">
    <header-nav></header-nav>
    <b-container class="con-wrap" ref="conWrap">
      <b-row class="header-con">
        <b-col class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div class="product-pic-wrap">
            <pic-zoom :gallerySlide="parametersimgList"></pic-zoom>
            <div class="model">型号：{{ marque }}</div>
          </div>
        </b-col>
        <b-col class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div class="product-detail-info">
            <div class="product-title">
              <span class="brand-name" @click="toBrand(brandId)">{{ goodsInfo.brand_name }}</span>
              <span class="style-name">{{ goodsInfo.style_name }}</span>
              <span>{{ goodsInfo.goods_name }}</span>
            </div>
            <div class="price-detail">
              <div class="price-inner-wrap">
                <div class="price-wrap">
                  <div class="price" v-if="showOriginalPrice">
                    <span class="price-title">e家价</span>
                    <span class="original-price">￥{{ goodsInfo.sale_price }}</span>
                  </div>
                  <div class="price">
                    <span class="price-title">{{ priceTip }}</span>
                    <span class="price-normal">￥{{ price }}</span>
                  </div>
                </div>
                <div class="brand-logo">
                  <div v-for="(item, index) in brandLogoArr" :key="index">
                    <img :src="item.logo"
                      v-if="item.goodsType == goodsInfo.goods_type && item.styleType == goodsInfo.style_type && !brandLogo.brand_img"
                      @click="toSearchResult(item.brandType)">
                  </div>
                  <img :src="brandLogo.brand_img" v-if="brandLogo.brand_img" @click="toBrand(brandId)">
                </div>
              </div>
            </div>
            <div class="quantity-items-wrap">
              <div class="item">交易成功<span class="quantity">{{ goodsInfo.sale_count }}</span></div>
              <div class="item">累计评价<span class="quantity">0</span></div>
              <div class="item">
                <a href="javascript: void(0);" @click="showSunCode">
                  <span class="item-title">扫码浏览</span>
                  <i class="icon-phone"></i>
                  <i :class="showCode ? 'el-icon-arrow-up rotate' : 'el-icon-arrow-up'"></i>
                </a>
                <div :class="showCode ? 'sun-code-wrap active' : 'sun-code-wrap'">
                  <img :src="sunCode" alt="suncode">
                </div>
              </div>
            </div>

            <div class="items-wrap" v-for="(item, index) in goodsDetailParams" :key="index">
              <span class="title">{{ item.parameters_name }}</span>
              <div class="items">
                <div :class="['item', idx == obj.activeItem ? 'active' : '']" v-for="(obj, idx) in item.parameters"
                  :key="idx" @click="selectItem(index, idx)">
                  <p>{{ obj.parameters_name }}</p>
                </div>
              </div>
            </div>

            <div class="items-wrap">
              <span class="title">服务</span>
              <div class="items">
                <p>付款后15-35天内发货，物流周期3-7天。</p>
              </div>
            </div>
            <div class="items-wrap">
              <span class="title">数量</span>
              <div class="items">
                <el-input-number size="mini" v-model="quantity" @change="quantityChange" :min="1"
                  :max="10"></el-input-number>
                <span class="stock">(库存仅剩{{ goodsInfo.inventory_count == 0 ? 99 : goodsInfo.inventory_count }}件)</span>
              </div>
            </div>
            <div class="items-wrap">
              <div class="title"></div>
              <div class="items">
                <div class="buy-now" @click="buyNow">立即购买</div>
                <div class="add-to-cart" @click="addToCart">加入购物车</div>
                <div class="collection">
                  <span @click="collection">
                    <b-icon-heart v-if="!collect"></b-icon-heart>
                    <b-icon-heart-fill v-else></b-icon-heart-fill>
                  </span>
                </div>
              </div>
            </div>
            <div class="items-wrap">
              <div class="title">备注</div>
              <div class="items">
                <p class="tip">拍照设备、环境灯光及显示器等因素可能会导致网站图片与实物颜色存在偏差，本站不支持色差原因退换货，下单前请确认好实物颜色!</p>
              </div>
            </div>
            <div class="items-wrap" v-if="goodsInfo.modelId">
              <div class="title"></div>
              <div class="items">
                <el-button class="to-3DModel" type="warning" plain @click="to3DModelDetail">模型下载</el-button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="tabs">
            <div :class="['tab-item', index == firstTabActive ? 'active' : '']" v-for="(item, index) in firstTabsArr"
              :key="index" @click="firstTabChange(index)">{{ item }}</div>
          </div>
          <div class="popular-type" v-if="firstTabActive == 1">
            <span :class="popular.popularTypeId == item.label_id ? 'asp' : ''" v-for="(item, index) in popular.popularType"
              :key="index" @click="changePopularType(item.label_id, index)">{{ item.label_name }}</span>
          </div>
        </b-col>
        <b-col class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="relevant-schemes" v-if="firstTabActive == 2">
            <div class="schemes-wrap">
              <div class="schemes-inner-wrap swiper-container">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(item, index) in schemeItems" :key="index" @click="goToCanvasDetail(item)">
                    <div class="pic">
                      <img :src="item.coverUri" alt="">
                    </div>
                    <div class="detail">
                      <div class="name">{{ item.name }}</div>
                      <div class="info"> {{ item.sacreage }}㎡</div>
                      <!-- <div class="info">{{ item.hourseType }} | {{ item.style }} | {{ item.sacreage }}㎡</div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-button-next scheme-slide-next"></div>
              <div class="swiper-button-prev scheme-slide-prev"></div>
            </div>
          </div>
          <div class="popular-items" v-else-if="firstTabActive == 0" ref="wrap">
            <div class="target-product">
              <div class="pic">
                <img :src="parametersimgList[0]" alt="">
              </div>
              <div class="name">{{ goodsInfo.brand_name }}{{ goodsInfo.style_name }}{{ goodsInfo.goods_name }}</div>
              <div class="price">￥{{ price }}</div>
            </div>
            <div class="add">
              <img src="@/../public/icon/product/details/jia.png">
            </div>
            <div class="product-items">
              <div class="carousel swiper-container" ref="carousel">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(item, index) in popular.popularGoods" :key="index"
                    @click.stop.prevent="selectPopularGoods($event, item)">
                    <div class="pic" @click.stop="toDetail(item)">
                      <img :src="item.file_path" :alt="item.goods_name">
                    </div>
                    <div class="name" @click.stop="toDetail(item)">{{ item.goods_name }}</div>
                    <div class="price">
                      {{ item.sale_price }}
                      <el-checkbox v-model="item.checked"></el-checkbox>
                    </div>
                  </div>
                </div>
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
              </div>

            </div>
            <div class="equal">
              <img src="@/../public/icon/product/details/deng.png">
            </div>
            <div class="add-to-cart-wrap">
              <div class="pro-dut">{{ popular.selectPopularGoodsNum }}件商品搭配总价：</div>
              <div class="pro-price">￥{{ popular.selectPopularGoodsTotalPrice }}</div>
              <div class="pro-cart" @click="PopularGoodsAddToCart">加入购物车</div>
            </div>
          </div>
          <div class="similar-goods" v-else>
            <ul class="goods-items">
              <li v-for="(item, index) in similarGoods" :key="index">
                <div class="item" @click="toDetail(item)">
                  <div class="pic">
                    <img :src="item.file_path" :alt="item.name">
                  </div>
                  <div class="name">{{ item.goods_name }}</div>
                  <div class="price">￥{{ item.sale_price }}</div>
                </div>
              </li>
            </ul>
          </div>
        </b-col>
        <b-col>
          <div class="tabs">
            <div :class="['tab-item', index == secondTabActive ? 'active' : '']" v-for="(item, index) in secondTabsArr"
              :key="index" @click="secondTabChange(index)">{{ item }}</div>
          </div>
          <div class="product-detail" v-if="secondChangeTab">
            <div class="product-detail-con" v-if="goodsInfo.goods_introduce" v-html="goodsInfo.goods_introduce"></div>
            <div class="product-detail-empty" v-else>
              <span>暂无详情！</span>
            </div>
          </div>
          <div class="product-evaluate-wrap" v-else>
            <div class="product-evaluate">
              <div class="evaluate-info-bar">
                <b-row>
                  <b-col sm="6" md="6" lg="3" xl="3">
                    <div class="item">
                      <div class="total-score">
                        <span class="score"
                          v-if="goodsEvaluationData.describeAvg && goodsEvaluationData.logisticsAvg && goodsEvaluationData.serviceAvg">{{ goodsEvaluationData.describeAvg }}</span>
                        <span class="score" v-else>5.0</span>
                        <div class="unit">分</div>
                      </div>
                    </div>
                  </b-col>
                  <b-col sm="6" md="6" lg="3" xl="3">
                    <div class="item">
                      <span>描述相符</span>
                      <el-rate v-model="describeAvg" disabled show-score text-color="#ff9900">
                      </el-rate>
                      <span>分</span>
                    </div>
                  </b-col>
                  <b-col sm="6" md="6" lg="3" xl="3">
                    <div class="item">
                      <span>物流服务</span>
                      <el-rate v-model="logisticsAvg" disabled show-score text-color="#ff9900">
                      </el-rate>
                      <span>分</span>
                    </div>
                  </b-col>
                  <b-col sm="6" md="6" lg="3" xl="3">
                    <div class="item">
                      <span>售后服务</span>
                      <el-rate v-model="serviceAvg" disabled show-score text-color="#ff9900">
                      </el-rate>
                      <span>分</span>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="evaluate-type">
              <div :class="evalActive == index ? 'item active' : 'item'" v-for="(item, index) in evaluationItems"
                :key="index" @click="changeEvaluation(index)">{{ item }}</div>
            </div>
            <div class="evaluate-con" v-if="goodsEvaluationData.discuss.length > 0">
              <div class="evaluate-item" v-for="(item, index) in goodsEvaluationData.discuss" :key="index">
                <b-container fluid>
                  <b-row>
                    <b-col sm="12" md="3" lg="2" xl="2">
                      <div class="item-left">
                        <div class="avatar">
                          <img :src="item.head_photo" :alt="item.name">
                        </div>
                        <span>{{ `${item.name.slice(0, 2)}***${item.name.slice(-2)}` }}</span>
                      </div>
                    </b-col>
                    <b-col sm="12" md="9" lg="10" xl="10">
                      <div class="item-right">
                        <div class="star-wrap">
                          <el-rate v-model="item.avg" disabled :show-score="false" text-color="#ff9900">
                          </el-rate>
                        </div>
                        <div class="eval-txt">{{ item.discuss }}</div>
                        <div class="eval-pic" v-if="item.discussImgArr.length > 0">
                          <div class="pic-wrap" v-for="(img, idx) in item.discussImgArr" :key="idx">
                            <img :src="img" :alt="img">
                          </div>
                        </div>
                        <div class="goods-params">
                          <div class="params">
                            <span>规格：{{ item.parameters.split(',')[0] }}</span>
                            <span>材质：{{ item.parameters.split(',')[1] }}</span>
                          </div>
                          <div class="release-time">{{ item.create_date }}</div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </div>
            </div>
            <div class="evaluate-empty-con" v-else>
              <span>暂时还没有买家评论哦！</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
import headerNav from "@/components/Navbar";
import footerNav from "@/components/Footer";
import Swiper from "swiper/swiper-bundle.min";
import 'swiper/swiper-bundle.min.css';
import PicZoom from '@/components/PicZoom';
import dump from "@/util/dump";
import { BIconHeart, BIconHeartFill } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
export default {
  components: {
    headerNav,
    footerNav,
    PicZoom,
    BIconHeart,
    BIconHeartFill
  },
  data() {
    return {
      goodsId: "",
      marque: "",
      carouselPerView: 3,
      carouselSpaceBetween: 10,
      carouselSlidesPerGroup: 3,
      quantity: 1,
      firstTabsArr: ["人气搭配", "同类商品","关联方案"],
      firstTabActive: 0,
      secondTabsArr: ["商品详情", "商品评价"],
      secondTabActive: 0,
      secondChangeTab: true,
      goodsDetailParams: [],
      goodsInfo: [],
      roleId: "",
      token: null,
      price: "",
      priceTip: "e家价",
      showOriginalPrice: false,
      brandLogoArr: [
        {
          logo: require("@/../public/icon/product/details/jjian.png"),
          brandType: "极简",
          goodsType: 1,
          styleType: 16
        },
        {
          logo: require("@/../public/icon/product/details/qshe.png"),
          brandType: "轻奢",
          goodsType: 1,
          styleType: 15
        },
        {
          logo: require("@/../public/icon/product/details/zshi.png"),
          brandType: "中式",
          goodsType: 1,
          styleType: 53
        }
      ],
      brandLogo: "",
      // 太阳码
      sunCode: "",
      showCode: false,
      // 收藏
      collect: "",
      brandId: "",
      // 规格/尺寸、材质/面料 id字符串
      goodsParametersIdStr: "",
      // 规格/尺寸、材质/面料 名字字符串
      goodsParametersNameStr: "",
      parametersimgList: [],
      // 人气搭配
      popular: {
        popularType: [],
        popularTypeId: "",
        secondStyleType: "",
        thirdLabelId: "",
        popularGoods: [],
        popularGoodsArr: [],
        selectPopularGoodsNum: 1,
        selectPopularGoodsTotalPrice: 0
      },
      // 同类商品
      similarGoods: [],
      paramList: [],
      // 商品评价
      goodsEvaluationData: [],
      evalActive: 0,
      schemeItems: [
        {
          img: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/248ea60e-1fde-4fc4-a52e-ffae05cb74ac.png",
          name: "恒福美地花园",
          hourseType: "两房两厅",
          measureArea: "建筑 115.00㎡ 套内 92.35㎡",
          style: "极简",
        },
        {
          img: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/248ea60e-1fde-4fc4-a52e-ffae05cb74ac.png",
          name: "恒福美地花园",
          hourseType: "两房两厅",
          measureArea: "建筑 115.00㎡ 套内 92.35㎡",
          style: "极简",
        },
        {
          img: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/248ea60e-1fde-4fc4-a52e-ffae05cb74ac.png",
          name: "恒福美地花园",
          hourseType: "两房两厅",
          measureArea: "建筑 115.00㎡ 套内 92.35㎡",
          style: "极简",
        },
        {
          img: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/248ea60e-1fde-4fc4-a52e-ffae05cb74ac.png",
          name: "恒福美地花园",
          hourseType: "两房两厅",
          measureArea: "建筑 115.00㎡ 套内 92.35㎡",
          style: "极简",
        },
        {
          img: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/248ea60e-1fde-4fc4-a52e-ffae05cb74ac.png",
          name: "恒福美地花园",
          hourseType: "两房两厅",
          measureArea: "建筑 115.00㎡ 套内 92.35㎡",
          style: "极简",
        },
        {
          img: "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/248ea60e-1fde-4fc4-a52e-ffae05cb74ac.png",
          name: "恒福美地花园",
          hourseType: "两房两厅",
          measureArea: "建筑 115.00㎡ 套内 92.35㎡",
          style: "极简",
        }
      ]
    }
  },
  created() {
    this.init();
  },
  mounted() {
    addEventListener("resize", () => {
      this.carousel();
    }, false);
    this.schemesSwiper();
  },
  computed: {
    describeAvg() {
      return parseFloat(this.goodsEvaluationData.describeAvg || 0);
    },
    logisticsAvg() {
      return parseFloat(this.goodsEvaluationData.logisticsAvg || 0);
    },
    serviceAvg() {
      return parseFloat(this.goodsEvaluationData.serviceAvg || 0);
    },
    evaluationItems() {
      return [
        `全部评价(${this.goodsEvaluationData.countAll})`,
        `图片(${this.goodsEvaluationData.countImg})`,
        `好评(${this.goodsEvaluationData.countGood})`,
        `中评(${this.goodsEvaluationData.countMid})`,
        `差评(${this.goodsEvaluationData.countBad})`
      ];
    },
    // slideData(){
    //   const slideObj = {
    //     hasVideo: false,
    //     slideParams: this.parametersimgList
    //   },
    //   video = {};

    //   // 判断该商品是否有视频
    //   if(this.goodsInfo.video_path && this.goodsInfo.cover_path){
    //     video.poster = this.goodsInfo.cover_path;
    //     video.video = this.goodsInfo.video_path;
    //   }


    //   // 判断对象是否为空对象
    //   if(Object.keys(video).length == 0){
    //     // 空对象
    //     slideObj.hasVideo = false;
    //   }else{
    //     // 非空对象
    //     slideObj.hasVideo = true;
    //     slideObj.slideParams.unshift(video);
    //   }
    //   return slideObj
    // }
  },
  methods: {
    init() {
      //this.goodsId = "14285"; //"14285" "5013144" "10629"
      this.goodsId = this.$route.query.good_id;
      this.roleId = localStorage.getItem("role_id");
      this.token = localStorage.getItem("token");
      // 取消身份判断。所有身份都显示e家价
      // this.showOriginalPrice = (this.roleId != 3 && this.token != null && this.token != "null") ? true : false;
      this.getGoodsInfo();
      // this.getGoodsParams();
      // this.getGoodsSkuInfo();
      this.getBrandLogo();
      this.goodsSunCode();
      this.getGoodsEvaluation();
      this.getAllMasterpieceReview();
    },

    goToCanvasDetail(item){
      // console.log(item.id)
      // let link = "/twodimensionalprogramme"
    let data = {
      link: "/twodimensionalprogramme",
      type: 3,
      params:{
        id: item.id
      }
    
      // link: link
    }
    dump.link(data);
    },

    // 获取商品信息
    async getGoodsInfo() {
      await new Promise(resolve => this.$api.getGoodsInfo({
        goods_id: this.goodsId,
        token: this.token
      }).then(res => {
        if (res.status === 100) {
          this.goodsInfo = res.data;
          this.brandId = res.data.brand_id;
          this.popular.secondStyleType = res.data.second_style_type;
          this.popular.thirdLabelId = res.data.third_label_id;
          this.collect = res.data.collection_id;
          resolve(res)
          if (res.data.is_promotion == 1) {
            this.priceTip = "e家价";
            this.price = res.data.discountPrice;
          } else {
            this.price = res.data.sale_price;
            /* 取消身份判断。所有身份都显示e家价
            if(this.roleId == '38e045588e0e4ce19b80ca40f5325934' && (this.token != null && this.token != "null")){
              // 分销商
              this.priceTip = "分销商价";
            }else if(this.roleId == 'de9de2f006e145a29d52dfadda295353' && (this.token != null && this.token != "null")){
              // 运营商
              this.priceTip = "运营商价";
            }else if(this.roleId == "4" && (this.token != null && this.token != "null")){
              // 会员
              this.priceTip = "会员价";
            }else{
              this.priceTip = "e家价";
            }
            */
            this.priceTip = "e家价";
          }
        }

      }).catch(err => console.error(err))

      )
      // 人气搭配商品类型
      this.getPopularGoodsTypes();
      // 同类商品
      this.getSimilarGoods();
      this.getGoodsParams()
    },
    // 获取商品详情参数
    getGoodsParams() {
      this.$api.getGoodsParams({
        goods_id: this.goodsId,
      }).then(res => {
        if (res.status === 100) {
          this.goodsDetailParams = res.data;
          const goodsParametersIdArr = [],
            goodsParametersNameArr = [];
          this.goodsDetailParams.forEach(item => {
            item.parameters.forEach((obj, idx) => {
              this.$set(obj, "activeItem", null);
              if (!idx) {
                goodsParametersIdArr.push(obj.goodsparameters_id);
                goodsParametersNameArr.push(obj.parameters_name);
                this.$set(obj, "activeItem", 0);
              }
            })
          })
          this.goodsParametersIdStr = goodsParametersIdArr.join(",");
          this.goodsParametersNameStr = goodsParametersNameArr.join(",");
          this.paramList.push({
            goods_id: this.goodsId,
            goods_number: 1,
            goods_parameter: this.goodsParametersNameStr,
            parameters: this.goodsParametersIdStr
          })
          this.getParamsInfo();
          console.log(this.goodsDetailParams)
          console.log(this.paramList)
        }
      }).catch(err => console.error(err));
    },
    // 获取商品SKU信息
    // getGoodsSkuInfo() {
    //   this.$api.goodsSkuInfo({
    //     goods_id: this.goodsId,
    //     token: this.token
    //   }).then(res => {
    //     console.log(res)
    //   }).catch(err => console.error(err));
    // },
    getAllMasterpieceReview(){
      this.$api.getAllMasterpieceReview({
        pageIndex: 1,
        pageSize: 20,
        token: this.token,
        goodsId: this.goodsId
      }).then(res =>{
        console.log("review",res)
        this.schemeItems = res.data.list
      })
    },
    // 获取商品太阳码
    goodsSunCode() {
      this.$api.goodsSunCode({
        token: this.token,
        goodsId: this.goodsId
      }).then(res => {
        if (res.status === 100) {
          let blob = this.base64ToBlob(res.data);
          this.sunCode = URL.createObjectURL(blob);
        }
      }).catch(err => console.error(err))
    },
    // base64转Blob
    base64ToBlob(urlData, type) {
      let arr = urlData.split(',');
      let mime = arr[0].match(/:(.*?);/)[1] || type;
      // 去掉url的头，并转化为byte
      let bytes = window.atob(arr[1]);
      // 处理异常,将ascii码小于0的转换为大于0
      let ab = new ArrayBuffer(bytes.length);
      // 生成视图（直接针对内存）：8位无符号整数，长度1个字节
      let ia = new Uint8Array(ab);
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], {
        type: mime
      });
    },
    // 打开或关闭太阳码
    showSunCode() {
      this.showCode = !this.showCode;
    },
    // 商品评价
    getGoodsEvaluation() {
      this.$api.goodsEvaluation({
        goods_id: this.goodsId
      }).then(res => {
        if (res.status === 100) {
          this.goodsEvaluationData = res.data;
          this.goodsEvaluationData.discuss.forEach(item => {
            this.$set(item, 'discussImgArr', []);
            item.discuss_img.split(",").forEach((o, i) => {
              if (o) {
                item.discussImgArr[i] = o;
              }
            })
          })
        }
      }).catch(err => console.error(err));
    },
    // 点击切换商品评价
    changeEvaluation(i) {
      this.evalActive = i;
      const params = {
        goods_id: this.goodsId
      }
      switch (i) {
        case 0: params.appraise_level = "";
          break;
        case 1: params.img = 1;
          break;
        case 2: params.appraise_level = 3;
          break;
        case 3: params.appraise_level = 2;
          break;
        case 4: params.appraise_level = 1;
          break;
      }

      this.$api.goodsEvaluation(params).then(res => {
        if (res.status === 100) {
          this.goodsEvaluationData = res.data;
          this.goodsEvaluationData.discuss.forEach(item => {
            this.$set(item, 'discussImgArr', []);
            item.discuss_img.split(",").forEach((o, i) => {
              if (o) {
                item.discussImgArr[i] = o;
              }
            })
          })
        }
      }).catch(err => console.error(err));
    },
    // 跳转至品牌页
    toBrand(bId) {
      const params = {
        type: 3,
        link: "/brandDetails",
        params: {
          brand_type: 1,
          brand_id: bId
        }
      }
      dump.link(params);
    },
    // 搜索品牌
    toSearchResult(str) {
      const params = {
        type: 3,
        link: "/search",
        params: {
          keywords: str
        }
      }
      dump.link(params);
    },
    // 获取品牌logo
    getBrandLogo() {
      this.$api.getBrandLogo({
        goods_id: this.goodsId
      }).then(res => {
        if (res.status === 100) {
          this.brandLogo = res.data;
        }
      }).catch(err => console.error(err));
    },
    // 选择规格/尺寸 和 材质/面料
    selectItem(i, k) {
      const goodsParametersIdArr = [],
        goodsParametersNameArr = [];
      this.goodsDetailParams[i].parameters.forEach(item => {
        item.activeItem = null;
      })
      this.goodsDetailParams[i].parameters[k].activeItem = k;
      this.goodsDetailParams.forEach(item => {
        item.parameters.forEach(obj => {
          if (obj.activeItem !== null) {
            goodsParametersIdArr.push(obj.goodsparameters_id);
            goodsParametersNameArr.push(obj.parameters_name);
          }
        })
      })
      this.goodsParametersIdStr = goodsParametersIdArr.join(",");
      this.goodsParametersNameStr = goodsParametersNameArr.join(",");
      this.getParamsInfo();
      this.paramList.forEach((item, index) => {
        if (item.goods_id == this.goodsId) {
          this.paramList.splice(index, 1, {
            goods_id: this.goodsId,
            goods_number: 1,
            goods_parameter: this.goodsParametersNameStr,
            parameters: this.goodsParametersIdStr,
          })
        }
      })
      console.log(this.paramList)
    },
    // 获取商品参数信息
    getParamsInfo() {
      this.$api.getParamsInfo({
        goods_id: this.goodsId,
        parameters: this.goodsParametersIdStr,
        brand_id: this.brandId,
        token: this.token,
        shopId: this.cookie.getCookie("shopId")
      }).then(res => {
        if (res.status === 100) {
          this.parametersimgList = res.data.parametersimgList;
          console.log("list",this.parametersimgList)
          console.log("参数", this.goodsParametersIdStr)
          this.marque = res.data.marque;
          this.goodsInfo.sale_price = res.data.sale_price;
          this.price = res.data.discountPrice;
          if (this.popular.selectPopularGoodsTotalPrice) {
            this.popular.selectPopularGoodsTotalPrice = (this.popular.selectPopularGoodsTotalPrice - this.price + res.data.discountPrice * 1).toFixed(2);
          } else {
            this.popular.selectPopularGoodsTotalPrice = (this.price * 1).toFixed(2);
          }

          // console.log(this.price)

          this.$nextTick(() => {
            this.$bus.$emit('init');
            // this.swiper();
          })
        }
      }).catch(err => console.error(err))
    },
    // 人气搭配商品类型
    getPopularGoodsTypes() {
      this.$api.getPopularGoodsTypes({
        goods_id: this.goodsId,
        brand_id: this.brandId
      }).then(res => {
        if (res.status === 100) {
          this.popular.popularType = res.data;
          this.popular.popularType.forEach((item, index) => {
            if (!index) {
              this.popular.popularTypeId = item.label_id;
            }
            this.popular.popularGoodsArr.push([]);
          })

          // 获取人气搭配商品
          this.getPopularGoods(0);
        }
      }).catch(err => console.error(err));
    },
    // 切换人气商品类型
    changePopularType(id, i) {
      this.popular.popularTypeId = id;
      this.getPopularGoods(i);
    },
    //获取人气搭配商品
    getPopularGoods(i) {
      // 判断popularGoodsArr是否有数据；有则直接引用popularGoodsArr里面的数据
      if (this.popular.popularGoodsArr[i].length > 0) {
        this.popular.popularGoods = this.popular.popularGoodsArr[i];
        return this.carousel();
      }

      this.$api.getPopularGoods({
        brand_id: this.brandId,
        label_id: this.popular.popularTypeId,
        token: this.token,
        second_style_type: this.popular.secondStyleType,
        third_label_id: this.popular.thirdLabelId
      }).then(res => {
        if (res.status === 100) {
          this.popular.popularGoodsArr[i] = res.data;
          this.popular.popularGoodsArr[i].forEach(item => {
            this.$set(item, "checked", false);
          })
          this.popular.popularGoods = this.popular.popularGoodsArr[i];

          this.carousel();
        }
      }).catch(err => console.error(err));
    },
    // 选购人气搭配商品
    selectPopularGoods(e, obj) {
      // 因为原生click事件会执行两次，第一次在label标签上，第二次在input标签上，故此处理
      if (e.target.tagName === "INPUT") return;
      obj.checked = !obj.checked;
      this.popular.selectPopularGoodsTotalPrice = this.popular.selectPopularGoodsTotalPrice * 1;
      if (obj.checked) {
        this.popular.selectPopularGoodsTotalPrice += obj.discountPrice * 1;
        this.popular.selectPopularGoodsNum++;
      } else {
        this.popular.selectPopularGoodsTotalPrice -= obj.discountPrice * 1;
        this.popular.selectPopularGoodsNum--;
      }
      this.popular.selectPopularGoodsTotalPrice = this.popular.selectPopularGoodsTotalPrice.toFixed(2);
      // 当选中人气搭配商品时请求getGoodsParams API 添加 规格/尺寸 和 材质/面料 的组合字符串对象到this.paramList
      if (obj.checked) {
        this.$api.getGoodsParams({
          goods_id: obj.goods_id
        }).then(res => {
          console.log(res);
          if (res.status === 100) {
            const goodsParametersIdArr = [],
              goodsParametersNameArr = [];
            res.data.forEach(item => {
              item.parameters.forEach((obj, idx) => {
                if (!idx) {
                  goodsParametersIdArr.push(obj.goodsparameters_id);
                  goodsParametersNameArr.push(obj.parameters_name);
                }
              })
            })

            // 添加 规格/尺寸 和 材质/面料 的组合字符串对象
            this.paramList.push({
              goods_id: obj.goods_id,
              goods_number: 1,
              goods_parameter: goodsParametersNameArr.join(","),
              parameters: goodsParametersIdArr.join(",")
            })
          }
        }).catch(err => console.error(err));
      } else {
        // 用户取消时删除 规格/尺寸 和 材质/面料 的组合字符串对象
        this.paramList.forEach((item, index) => {
          if (item.goods_id == obj.goods_id) {
            this.paramList.splice(index, 1);
          }
        })
      }
    },
    // 同类商品
    getSimilarGoods() {
      this.$api.getSimilarGoods({
        second_style_type: this.popular.secondStyleType,
        third_label_id: this.popular.thirdLabelId,
        brand_id: this.brandId
      }).then(res => {
        if (res.status === 100) {
          this.similarGoods = res.data;
        }
      }).catch(err => console.error(err));
    },
    // 跳转到商品详情
    toDetail(obj) {
      const params = {
        type: 2,
        link: "/#/product/details?good_id=" + obj.goods_id
      }
      dump.link(params);
    },
    // 立即购买
    buyNow() {
      // 检测用户是否为登录状态
      if (!dump.checkLogin()) {
        return false;
      }
      this.$api.submitOrder({
        type: 1,
        goods_id: this.goodsId,
        goods_parameter: this.goodsParametersNameStr,
        goods_number: this.quantity,
        parameters: this.goodsParametersIdStr,
        token: this.token
      }).then(res => {
        if (res.status === 100) {
          const params = {
            type: 1,
            link: "/orderSettlement",
            params: {
              orderid: res.data
            }
          }

          dump.link(params);
        }
      }).catch(err => console.error(err));
    },
    // 加入购物车
    addToCart() {
      // 检测用户是否为登录状态
      if (!dump.checkLogin()) {
        return false;
      }
      this.$api.addToCart({
        goods_id: this.goodsId,
        goods_parameter: this.goodsParametersNameStr,
        goods_number: this.quantity,
        parameters: this.goodsParametersIdStr,
        img_path: "",
        token: this.token,
        shopId: this.cookie.getCookie("shopId")
      }).then(res => {
        if (res.status === 100) {
          this.$message({
            message: '已成功加入购物车',
            type: 'success'
          });
        }
      }).catch(err => console.error(err));
    },
    // 人气搭配加入购物车
    PopularGoodsAddToCart() {
      this.$api.popularGoodsAddToCart({
        token: this.token,
        paramList: JSON.stringify(this.paramList)
      }).then(res => {
        console.log(res);
        if (res.status === 100) {
          this.$message({
            message: '已成功加入购物车',
            type: 'success'
          });
        }
      }).catch(err => console.error(err));
    },
    // 收藏
    collection() {
      // 检测用户是否为登录状态
      if (!dump.checkLogin()) {
        return false;
      }
      this.$api.collect({
        collection_type: 1,
        project_id: this.goodsId,
        token: this.token
      }).then(res => {
        if (res.status === 100) {
          this.collect = res.data;
        }
      }).catch(err => console.error(err));
    },
    quantityChange(val) {
      console.log(val);
    },
    schemesSwiper() {
      const schemeSwiper = () => {
        new Swiper('.schemes-inner-wrap', {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 10,
          // loop: true,
          // loopFillGroupWithBlank: true,
          observer: true,
          observeParents: true,
          navigation: {
            nextEl: ".scheme-slide-next",
            prevEl: ".scheme-slide-prev",
          },
        });
      }
      this.$nextTick(() => {
        schemeSwiper();
      })
    },
    carousel() {
      let wrapW = this.$refs.wrap.clientWidth;
      const popularCarousel = () => {
        new Swiper('.carousel', {
          slidesPerView: this.carouselPerView,
          spaceBetween: this.carouselSpaceBetween,
          slidesPerGroup: this.carouselSlidesPerGroup,
          observer: true,
          observeParents: true,
          navigation: {
            nextEl: '.carousel .swiper-button-next',
            prevEl: '.carousel .swiper-button-prev'
          },
          // loop: true,
          // loopFillGroupWithBlank: true,

        });
      }

      this.$nextTick(() => {
        if (wrapW > 930) {
          // this.carousel();
          this.carouselPerView = 3;
          this.carouselSpaceBetween = 10;
          this.carouselSlidesPerGroup = 3;
          popularCarousel();
        } else if (wrapW <= 930 && wrapW > 690 || wrapW <= 510) {
          this.carouselPerView = 2;
          this.carouselSpaceBetween = 20;
          this.carouselSlidesPerGroup = 2;
          // this.carousel();
          popularCarousel();

        } else if (wrapW <= 690 && wrapW > 510) {
          this.carouselPerView = 1;
          this.carouselSpaceBetween = 0;
          this.carouselSlidesPerGroup = 1;
          // this.carousel();
          popularCarousel();
        }
      })
    },
    firstTabChange(i) {
      this.firstTabActive = i;
      console.log(i)
      switch (i) {
        case 0: 
          break;
        case 1: this.$nextTick(() => { this.carousel(); })
          break;
        case 2: this.$nextTick(() => { this.schemesSwiper(); })
          break;
      }
    },
    secondTabChange(i) {
      this.secondTabActive = i;
      if (!i) {
        this.secondChangeTab = true;
      } else {
        this.secondChangeTab = false;
      }
    },
    // 查看商品模型
    to3DModelDetail() {
      if (this.goodsInfo.modelId) {
        const params = {
          type: 1,
          link: "/model3Ddetail",
          params: {
            id: this.goodsInfo.modelId
          }
        }
        dump.link(params);
      } else {
        this.$message.error('该商品没有商品模型');
      }
    }
    // changeSwiper(i){
    //   this.galleryTop.slideToLoop(i, 1000, false);
    // }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style";
@import "@/view/product/details/details";
</style>
